/* -------------------------------------------------------------------------- */
/*                                Falcon Button                               */
/* -------------------------------------------------------------------------- */
/* prettier-ignore */
/*LOADING*/
#loader {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    background: rgba(255, 255, 255, 0.9);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    color: #000;
  }
  
  #loader .loaderContent {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  
  #loader .loaderContent .turn.round.red {
    border-top: 3px #222 solid;
    -webkit-animation: loadingAnimation infinite;
    animation: loadingAnimation infinite;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  
  #loader .loaderContent .turn.round.yellow {
    border-bottom: 3px #FFE807 solid;
    -webkit-animation: loadingAnimation infinite;
    animation: loadingAnimation infinite;
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
  }
  
  #loader .loaderContent .turn.round {
    width: 300px;
    height: 300px;
    position: absolute;
    border-radius: 50%;
  }
  
  #loader .loaderContent .loading-icon {
    width: 180px;
  }
  
  @-webkit-keyframes loadingAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @keyframes loadingAnimation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .navbar-vertical-collapsed #navbarVerticalNav .nav-item select {
    display: none;
  }
  
  .navbar span.font-sans-serif {
    color: #000 !important;
  }
  
  .card {
    padding: 10px;
  }
  
  svg.joueur {
    max-height: 450px;
  }
  
  svg.joueur .equipement.free {
    fill: #3df53d !important;
  }
  svg.joueur .equipement.free:hover {
    fill: green;
    cursor: pointer;
  }
  svg.joueur .equipement.booked {
    fill: orange !important;
  }
  svg.joueur .equipement.billed {
    fill: red !important;
  }
  
  .panneau.free {
    fill: #3df53d61 !important;
    stroke: #000;
    stroke-width: 0.5px;
  }
  .panneau.free:hover {
    fill: green;
    cursor: pointer;
    stroke-width: 0.5px;
  }
  .panneau.booked {
    fill: #ffa50080 !important;
    stroke: #000;
    stroke-width: 0.5px;
  }
  .panneau.billed {
    fill: #ff000080 !important;
    stroke: #000;
    stroke-width: 0.5px;
  }
  
  .error{
    color:red;
  }
  
  .error input{
    border-color:red;
  }
  
  
  #svgModal .modal-dialog {
    max-width: none !important;
  }
  #svgModal .modal-dialog #planBande svg, #svgModal .modal-dialog #planPanneau svg {
    max-width: 55%;
    display: block;
    margin: auto;
  }
  #svgModal .modal-dialog #planBande svg:hover, #svgModal .modal-dialog #planPanneau svg:hover {
    cursor: auto;
  }
  
  #planBande svg:hover, #planPanneau svg:hover {
    cursor: pointer;
  }
  
  .hide {
    display: none !important;
  }
  /*# sourceMappingURL=user.css.map */
  
  
  .table>:not(caption)>*>*{
    padding:.5rem .5rem;
  }
  
  
  .btn, [data-list] .page, .tox .tox-menu__footer .tox-button:last-child, .tox .tox-dialog__footer .tox-button:last-child, .tox .tox-menu__footer .tox-button--secondary, .tox .tox-dialog__footer .tox-button--secondary{
    padding:.125rem .8rem;
  }
  
  svg *{
    font-family:"Poppins",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }
  
  svg text{
    fill:black;
  }
  
  span.required {
    color: #EE0700;
  }
  
  .row{
    padding:10px 0;
  }
  
  .btn.btn-outline-primary{
    width:fit-content;
    margin:5px 0;
  }
  
  .nav-link-icon {
    text-align: center;
  }


  .form-check-input:checked{
    background-color: #222;
    border-color: #222;
  }

  .btn-primary, .navbar-vertical .btn-purchase, .tox .tox-menu__footer .tox-button:last-child, .tox .tox-dialog__footer .tox-button:last-child{
    background-color: #222;
    border-color: #222;
  }
  .btn-primary:hover, .navbar-vertical .btn-purchase:hover, .tox .tox-menu__footer .tox-button:last-child:hover, .tox .tox-dialog__footer .tox-button:last-child:hover{
    background-color: #222 !important;
    border-color: #222 !important;
  }
  .btn-check:checked+.btn-primary, .navbar-vertical .btn-check:checked+.btn-purchase, .tox .tox-menu__footer .btn-check:checked+.tox-button:last-child, .tox .tox-dialog__footer .btn-check:checked+.tox-button:last-child, .btn-check:active+.btn-primary, .navbar-vertical .btn-check:active+.btn-purchase, .tox .tox-menu__footer .btn-check:active+.tox-button:last-child, .tox .tox-dialog__footer .btn-check:active+.tox-button:last-child, .btn-primary:active, .navbar-vertical .btn-purchase:active, .tox .tox-menu__footer .tox-button:active:last-child, .tox .tox-dialog__footer .tox-button:active:last-child, .btn-primary.active, .navbar-vertical .active.btn-purchase, .tox .tox-menu__footer .active.tox-button:last-child, .tox .tox-dialog__footer .active.tox-button:last-child, .show>.btn-primary.dropdown-toggle, .navbar-vertical .show>.dropdown-toggle.btn-purchase, .tox .tox-menu__footer .show>.dropdown-toggle.tox-button:last-child, .tox .tox-dialog__footer .show>.dropdown-toggle.tox-button:last-child{
    background-color:#222;
    border-color:#222;
    color:#fff;
  }
  .btn-check:focus+.btn-primary, .navbar-vertical .btn-check:focus+.btn-purchase, .tox .tox-menu__footer .btn-check:focus+.tox-button:last-child, .tox .tox-dialog__footer .btn-check:focus+.tox-button:last-child, .btn-primary:focus, .navbar-vertical .btn-purchase:focus, .tox .tox-menu__footer .tox-button:focus:last-child, .tox .tox-dialog__footer .tox-button:focus:last-child{
    background-color:#222;
    border-color:#222;
    color:#fff;
  }

  .nav-pills .nav-link.active, .nav-pills .show>.nav-link{
    background-color: #9b9b9b;
  }
  .nav-link{
    color: #ffcb05;
  }

body, .navbar-glass, .navbar-vertical .navbar-collapse{
    background-color:#e0e0e0;
}

.btn-outline-primary{
    color: #222;
    border-color: #222;
}

.btn-outline-primary:hover{
    color: #fff !important;
    background-color: #222 !important;
    border-color:#222 !important;
}

.btn-check:checked+.btn-outline-primary, .btn-check:active+.btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show{
  background-color:#222;
  color:#fff !important;
}

.page-item.active .page-link, .page-link:hover{
    background-color: #222;
    border-color: #222;
    color:#fff;
}

thead, .table-striped>tbody>tr:nth-of-type(even){
    background-color:#e0e0e0 !important;
    color:#000;
}
.table-striped>tbody>tr:nth-of-type(odd){
    color:#000;
}

.navbar-vertical .navbar-nav .nav-item .nav-link.active{
    color: #000;
}

.loginTitle{
    color:#000; 
}

.nav-link:hover, .nav-link:focus {
    color: #222;
}

.accordion-button:not(.collapsed), .accordion-item{
    background-color:#e0e0e0;
    color:#000;
}

.accordion-body{
    background-color:#f7f7f7;
}

.prestationCard{
    background-color: #707070 !important;
}


.infoCircleRes{
  width:7px;
  color:orange;
}

.infoCircleRes.billed{
  color:red;
}

.infoCircleCat {
  position: absolute;
  right: 50px;
  color:green;
}

.accordion-button{
  background-color:#e0e0e0;
}

.card-body li a{
  color: #fff;
}

.table>:not(caption)>*>* {
  padding: 5px 7px;
}

td {
  vertical-align: middle;
}

.dataTables_info{
  display:inline-block;
  padding-top:0 !important;
}

.dataTables_paginate{
  display:inline-block;
  float:right;
}

.dt-buttons{
  display:inline-block;
}

.dataTables_filter{
  display:inline-block;
  float:right;
}

.alert{
  padding:5px 10px;
}

.alert .btn-close{
  float:right;
}

.dt-button-collection{
  margin-top:5px !important;
}

.dt-button.buttons-collection{
  background-color: #fff;
  color:#748194;
    border: 1px solid #748194;
    border-radius: 0.25rem !important;
    font-weight:500;
}

.dt-button.buttons-collection:hover{
  background-color: #748194;
  color:#fff;
}

.dt-button.buttons-collection .dt-down-arrow{
  color:#748194;
  margin-left:5px;
  display:none;
}

.prestationSponsorList .true::marker{
  color:red;
}

.prestationSponsorList .false::marker{
  color:orange;
}


.infoCircleCat.free .fa-circle{
  color:green;
}
.infoCircleCat.booked .fa-circle{
  color:orange;
}
.infoCircleCat.billed .fa-circle{
  color:red;
}


.paginate_button, #listingEntreprise_info{
  font-size:13.3px;
}
.dataTables_info{
  font-size:12px;
}

.paginate_button a{
  padding:3px;
}


.paginate_button.page-item.previous{
  margin-right:5px;
}
.paginate_button.page-item.next{
  margin-left:5px;
}

.paginate_button.page-item.previous a, .paginate_button.page-item.next a{
  padding:3px !important;
  margin:0 !important;
}

.paginate_button.page-item a{
  padding:3px 8px;
  margin: 0 3px !important; 
}

*{
  font-family:Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol !important; 
}

.page-item:first-child .page-link, .page-item:last-child .page-link{
  border-radius:.25rem !important;
}

.dt-buttons{
  display:none;
}

.dropdown-toggle::after{
  content:none;
}

.actionButtons{
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.actionButtons .dropdownAction{
  margin:5px !important;
  display:inline;
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color:#fff;
  background-color:#222;
  border-color:#ffcb05;
}

.modal .nav-tabs .nav-link.active, .modal .nav-tabs .nav-item.show .nav-link{
  color:#000;
  background-color:unset;
  border-color:#ffcb05;
}

.error{
  background-color: #f8e8e8;
  color: #bb2c2c;
  border-left: 4px solid #bb2c2c;
  border-radius: 0;
}

.success{
  background-color: #eef6e8;
  color: #5A7849;
  border-left: 4px solid #5A7849;
  border-radius: 0;
}

.alert, .error, p.error, .notice, .success, .infomessage, .errorbox {
  font-weight: 400;
  font-size: 13px;
  position: relative;
  padding: 15px 15px 15px 36px;
}

.error:before, .errorbox:before {
  content: "\f05e";
  color: #bb2c2c !important;
}
.success:before {
  content: "\f058";
  color: #5A7849 !important;
}

.error:before, .notice:before, .success:before, .infomessage:before, .errorbox:before {
  display: inline-block;
  font: normal normal normal 18px/1 FontAwesome,sans-serif;
  position: absolute;
  left: 14px;
  top: 14px;
}

ul.noteList{
  padding-left:0;
}

.noteList li{
  list-style:none;
  padding:5px;
  cursor:pointer;
}

.noteList .listAutor{
  display:block;
  font-size:0.7em;
}

.noteList .listTitle{
  display:block;
  font-weight:600;
  font-size:1em;
}
.noteList .listContent{
  font-size:0.8em;
}

.noteList li:nth-child(odd){
  background-color:#f1f1f1;
}

.noteList li{
  //border: 1px solid #a7a7a7;
  background-clip: border-box;
}

.footer{
  width:95%;
}


.noteList li{
  display:flex;
  align-items: center;
  flex-direction:row;
}
.noteList .actionElement{
  margin-left:auto;
}

.noItemImg{
  max-height:140px;
}

.nocommentSection{
  display:flex;
  align-items: center;
  justify-content: center;
}

.clickA{
  color:#000;
}
.clickA:hover{
  color:#000;
  text-decoration:none;
}

.clickA:hover{
  cursor: pointer;
}

.nocommentSection .title{
  color:#222;
}
.nocommentSection .description{
  font-size:0.9em;
  color:rgb(94, 110, 130);
}

.infoPersonnel{
  border-right:1px solid #f1f1f1f1;
}

.twoInput label{
  margin-lefT:1rem;
}

.actionElement a{
  cursor:pointer;
  margin: 0 3px;
}

.hideDescription .listContent{
  display:none;
}

a{
  color:#222;
}
a:hover{
  color:#333;
}

.modal{
  z-index:1051;
}

.table-striped>tbody>tr:nth-of-type(even){
  --falcon-table-accent-bg: #fbfbfb !important;
}

.btn {
  padding:1px 10px !important;
}

table td{
  padding:2px 10px;
}

.btn-outline-secondary{
  border-color:#fff;
  color:#fff;
}
.btn-outline-secondary:hover{
  background-color:#fff;
  border-color:#fff;
  color:#000;
}

.select2-container{
  width:100% !important;
}

.formError{
  color:#bb2c2c;
}
.formError input{
  border-color:#bb2c2c;
}

.btn-outline-third{
  color: #748194;
  border-color: #748194;
}

.taskElement {
  display:flex;
  justify-content:space-between;
}

a.dropdown-item{
  cursor:pointer;
}


.bg-free{
  background-image: none;
background-color:#5ac968;
}

.bg-reserved{
  background-image: none;
  background-color:orange;
  }

  .bg-sold{
    background-image: none;
    background-color:red;
    }


    .topNumber{
      position: absolute;
      font-size: 0.6em;
      padding: 1px 6px;
      background-color: #d10000;
      color: #fff;
      border-radius: 150px;
      margin-left:2px;
    }

    #contactToHide{
      display:none;
    } 
    .tabJoueur{
      text-align:center;
    }

    body tr.disabled{
      color:#9b9b9b !important;
    }

    td {
      height: 38px;
  }

  .roundedCircle{
    background-color: lightgrey;
    width: fit-content;
    padding: 10px;
    border-radius: 50%;
  }

  .roundedCircle:hover{
    cursor:default;
  }

  .isChecked{
    text-decoration: line-through;
  }

  input[type="checkbox"]:hover{
    cursor:pointer;
  }

  .imgTeam{
    max-width:50px;
  }

  .game-date {
    background-color: #ffcc00 !important;
  }

  .sandbox{
    width:100%;
    text-align:center;
  }

  .sandbox span{
    padding: 10px 30px;
    background-color: #BD2A2E;
    color: #fff;
    border-radius: 15px;
    font-size: 1.2em;
  }

  .allSponsor{
    color:#fff;
    margin-bottom:15px;
    padding-left:0;
  }
  .allSponsor:hover{
    color:#fff;
  }
